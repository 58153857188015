import React from "react"
import PropTypes from "prop-types"
import { Col, Container } from "reactstrap"
import { useTranslation } from "react-i18next"
import { MegaTagTitle } from "../../components"
import { UserTableContainer } from "./components/UserTableContainer"

export const Users = () => {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <MegaTagTitle title={"title.users"} />

      <div className="page-content">
        <Container fluid>
            <UserTableContainer />
        </Container>
      </div>
    </React.Fragment>
  )
}
