import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  entities: [],
  loading: false,
  error: null,
  page: 1,
  meta: null,
  filters: {
    values: {
      keywords: "",
      role: "director",
    },
  },
  sort: [{ id: "created_at", desc: true }],
}

const userAccountsListSlice = createSlice({
  name: "userAccountsList",
  initialState,
  reducers: {
    getList(state) {
      state.loading = true
      state.error = null
    },
    getListCompleted(state, action) {
      state.loading = false
      state.entities = action.payload.data
      state.meta = action.payload.meta
    },
    getListRejected(state, action) {
      state.loading = false
      state.error = action.payload
    },
    setPage(state, action) {
      state.page = action.payload
    },
    changeFilter(state, action) {
      state.page = 1
      state.filters.values = { ...state.filters.values, ...action.payload }
    },
    updateItem(state, action) {
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.id) {
          return {
            ...entity,
            ...action.payload,
          }
        } else {
          return entity
        }
      })
    },
    changeSort(state, action) {
      state.page = 1
      state.sort = action.payload.sort
    },
    cleanState() {
      return initialState
    },
  },
})
/**
 * @namespace
 * @property {function} getList
 * @property {function} getListCompleted
 * @property {function} getListRejected
 *  @property {function} setPage
 *  @property {function} updateItem
 *  @property {function} cleanState
 */
export const userAccountsList = userAccountsListSlice.actions

export const selectUserAccounts = state => state.userAccounts.list

export default userAccountsListSlice.reducer
