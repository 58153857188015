import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import toastr from "toastr"
import i18n from "i18n"
import { API } from "api"
import { userAccountAiChatAccessUpdate } from "./slice"
import { userAccountsList } from "../list/slice"

function* aiChatAccessUpdate({ payload: { id, data, onSuccess } }) {
  const { has_ai_chat_access } = data

  try {
    yield put(userAccountsList.updateItem({ id, has_ai_chat_access }))

    yield call(API.admin.userAccountUpdate, id, data)

    yield put(userAccountAiChatAccessUpdate.updateCompleted())

    toastr.success(i18n.t("saved_success"))

    if (onSuccess) {
      onSuccess()
    }
  } catch (error) {
    yield put(
      userAccountsList.updateItem({
        id,
        has_ai_chat_access: !has_ai_chat_access,
      })
    )

    const errors = error.response?.data?.errors || {}
    const message = error.response?.data?.message || i18n.t("error")

    yield put(
      userAccountAiChatAccessUpdate.updateRejected({
        errors,
        message,
      })
    )
    toastr.error(message)
  }
}

export function* watchUpdate() {
  yield takeEvery(userAccountAiChatAccessUpdate.update, aiChatAccessUpdate)
}

function* aiChatAccessUpdateSaga() {
  yield all([fork(watchUpdate)])
}

export default aiChatAccessUpdateSaga
