import axios from "axios"

import {
  agency,
  analytics,
  attachment,
  auth,
  billing,
  calendar,
  client,
  common,
  employee,
  expense,
  model,
  modelsGroups,
  packageApi,
  profile,
  rating,
  storage,
  users,
  website,
  share,
  chat,
  admin,
} from "../requests"

import configureStore from "../../store"
import { cleanAuthData } from "store/actions"

const { store } = configureStore()

const BASE_URL = process.env.REACT_APP_BASE_URL

axios.interceptors.request.use(
  config => {
    return {
      ...config,
      baseURL: BASE_URL,
      withCredentials: true,
      headers: { "Content-Type": "application/json", ...config.headers },
    }
  },
  error => {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (error.message === "Network Error") {
      console.log("Network Error")
    }
    if (error.response?.status) {
      switch (error.response?.status) {
        case 401:
          console.log("Error - 401")

          if (store.getState().auth.data.isLoggedIn) {
            store.dispatch(cleanAuthData())
          }

          break
        case 400:
          console.log("Error - 400")
          break
        case 419:
          console.log("Error - 419")

          store.dispatch(cleanAuthData())

          window.location.href = "/"

          break

        default:
          console.log("Server Error")
          break
      }
    }
    return Promise.reject(error)
  }
)

class APIService {
  auth = auth
  common = common
  profile = profile
  employee = employee
  model = model
  attachment = attachment
  rating = rating
  agency = agency
  client = client
  packageApi = packageApi
  website = website
  calendar = calendar
  expense = expense
  users = users
  analytics = analytics
  modelsGroups = modelsGroups
  billing = billing
  storage = storage
  share = share
  chat = chat
  admin = admin

  setToken = token => {
    axios.defaults.headers["Authorization"] = `Bearer ${token}`
  }

  clearToken = () => {
    axios.defaults.headers["Authorization"] = null
  }

  setLang = lang => {
    axios.defaults.headers["X-localization"] = lang
  }

  clearLang = () => {
    axios.defaults.headers["X-localization"] = null
  }
}

const API = new APIService()
export default API
