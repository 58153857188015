import React from "react"
import PropTypes from "prop-types"
import { CheckboxField } from "components"

export const AiChatAccess = ({ id, value, onUpdate }) => {
  const handleUpdate = () => {
    onUpdate(id, !value)
  }

  return (
    <div className={"d-flex justify-content-center"}>
      <CheckboxField
        value={value}
        onChange={() => handleUpdate()}
        wrapClass={"form-checkbox-control m-0"}
        size={"lg"}
        meta={{}}
      />
    </div>
  )
}

AiChatAccess.propTypes = {
  id: PropTypes.any.isRequired,
  value: PropTypes.bool.isRequired,
  onUpdate: PropTypes.func.isRequired,
}
