import React from "react"
import { ValidationsUtils } from "utils"

/**
 * Callback for adding useSubmitHandler
 *
 * @callback submitAction
 * @param {{onSuccess: function, onError: function,params: object}}
 * @return {void}
 */

/**
 * @function handleSubmit
 * @param {object} params
 * @param {object} onSubmitProps - {reset, setError}
 * @return {void}
 */

/**
 * Represents a useSubmitHandler for REACT HOOK FORM.
 * @constructor
 * @param {submitAction} submitAction
 * @param {function} onSuccess additional action onSuccess
 * @returns {{handleSubmit}}
 */

export const useSubmitHandler = (submitAction, onSuccess) => {
  const onSubmitSuccess = onSubmitProps => () => {
    const { reset } = onSubmitProps
    if (onSuccess) {
      onSuccess()
    }
    reset && reset()
  }

  const onSubmitError = onSubmitProps => errors => {
    const { setError } = onSubmitProps
    ValidationsUtils.setServerSideErrors(errors, setError)
  }

  const handleSubmit = (params, onSubmitProps) => {
    submitAction({
      params,
      onSuccess: onSubmitSuccess(onSubmitProps),
      onError: onSubmitError(onSubmitProps),
      reset: onSubmitProps.reset
    })
  }

  return { handleSubmit }
}
