import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  loading: false,
  error: null,
}

const userAccountAiChatAccessUpdateSlice = createSlice({
  name: "userAccountAiChatAccessUpdate",
  initialState,
  reducers: {
    update(state) {
      state.loading = true
      state.error = null
    },
    updateCompleted(state) {
      state.loading = false
    },
    updateRejected(state, action) {
      state.loading = false
      state.error = action.payload
    },
    cleanState() {
      return initialState
    },
  },
})
/**
 * @namespace
 * @property {function} update
 *  @property {function} updateCompleted
 *  @property {function} updateRejected
 *  @property {function} cleanState
 */
export const userAccountAiChatAccessUpdate = userAccountAiChatAccessUpdateSlice.actions

export default userAccountAiChatAccessUpdateSlice.reducer
