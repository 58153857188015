import { combineReducers } from "redux"

import Layout from "./layout/reducer"
import auth from "./auth/reducers"
import user from "./user/reducers"
import profile from "./profile/reducers"
import staticReducers from "./static/reducers"
import multipleUpload from "./multipleUpload/slice"
import app from "./app/reducers"
import userIdeas from "./userIdeas/reducers"
import userAccounts from "./userAccounts/reducers"
import staticPages from "./staticPages/reducers"

const rootReducer = combineReducers({
  Layout,
  auth,
  user,
  profile,
  static: staticReducers,
  multipleUpload,
  app,
  userIdeas,
  userAccounts,
  staticPages,
})

export default rootReducer
