import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import toastr from "toastr"
import i18n from "i18n"
import { API } from "api"
import { activateCompany } from "./slice"
import { userAccountsList } from "../list/slice"

function* activate({ payload: { id, onSuccess } }) {
  try {
    const response = yield call(API.admin.activateCompany, id)

    const { data } = response

    yield put(userAccountsList.updateItem({ ...data?.data }))

    yield put(activateCompany.activateCompleted())

    toastr.success(i18n.t("activate.success.message"))

    if (onSuccess) {
      onSuccess()
    }
  } catch (error) {
    const errors = error.response?.data?.errors || {}
    const message = error.response?.data?.message || i18n.t("error")

    yield put(
      activateCompany.activateRejected({
        errors,
        message,
      })
    )
    toastr.error(message)
  }
}

export function* watchActivate() {
  yield takeEvery(activateCompany.activate, activate)
}

function* activateSaga() {
  yield all([fork(watchActivate)])
}

export default activateSaga
