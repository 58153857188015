/* eslint react/prop-types: off */
import React, { useMemo, useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Card, CardBody, Col, Row } from "reactstrap"
import moment from "moment"

import { ReactTableView } from "../../ReactTableView"
import { Pagination } from "../../Pagination"

import * as HOC from "../../../HOC"
import { CroppedImage } from "../../CroppedImage"
import placeholder from "assets/images/dev/avatar-placeholder-square.svg"
import { billingSubscriptionHelper } from "../../../helpers"
import { IconButton } from "../../Buttons"
import { ROLE_DIRECTOR } from "../../../consts"
import { DeactivateCompany } from "./DeactivateCompany"
import { ActivateCompany } from "./ActivateCompany"
import { AiChatAccess } from "./AiChatAccess"

const TableDataLoading = HOC.withTableDataLoading()

export const UserAccountsTable = props => {
  const { t } = useTranslation()

  const data = useMemo(() => {
    return props.entities
  }, [props.entities])

  const [columns, setColumns] = useState([
    {
      Header: t("name"),
      accessor: "name",
      id: "name",
      Cell: ({ row: { original } }) => {
        return (
          <div className={"d-flex gap-2"}>
            <div className={"avatar-rounded avatar-rounded__without-crop mr-2"}>
              <CroppedImage
                key={original.id}
                placeholder={placeholder}
                baseSrc={original.avatar}
                alt={original.name}
              />
            </div>
            <div className={"align-self-center"}>{original.name}</div>
          </div>
        )
      },
    },
    {
      Header: t("date"),
      accessor: "created_at",
      id: "created_at",
      Cell: ({ value }) => {
        return value ? moment(value).format("DD/MM/YYYY") : "-"
      },
    },
    // {
    //   Header: t("company"),
    //   accessor: "company",
    //   id: "company_name",
    //   Cell: ({ row: { original } }) => original.company?.name,
    // },
    {
      Header: t("email"),
      accessor: "email",
      id: "email",
      Cell: ({ value }) => value,
    },
    {
      Header: t("subscription_plan"),
      accessor: "subscription",
      id: "subscription_name",
      Cell: ({ value }) => (value?.tariff ? value.tariff?.name : "-"),
      disableSortBy: true,
    },
    {
      Header: t("subscription_status"),
      accessor: "subscription",
      id: "subscription_status",
      Cell: ({ value }) =>
        value?.status
          ? billingSubscriptionHelper.status.getStatus(value.status).label
          : "-",
      disableSortBy: true,
    },
    {
      Header: t("billed_period"),
      accessor: "subscription",
      id: "subscription_period",
      Cell: ({ value }) =>
        value?.tariff
          ? billingSubscriptionHelper.period.getConfig(value.tariff?.period)
              .label
          : "-",
      disableSortBy: true,
    },
    {
      Header: t("last-activity"),
      accessor: "last_activity",
      id: "last_activity",
      Cell: ({ value }) => {
        return value
          ? moment.utc(value).local().format("DD/MM/YYYY HH:mm")
          : "-"
      },
      disableSortBy: true,
    },
    {
      Header: (
        <span className={"text-center w-100"}>{t("tariff.ai_chat.label")}</span>
      ),
      accessor: "has_ai_chat_access",
      id: "has_ai_chat_access",
      Cell: ({ row: { original } }) => {
        return (
          <AiChatAccess
            id={original?.id}
            value={original?.has_ai_chat_access}
            onUpdate={props.onAiChatAccessUpdate}
          />
        )
      },
      disableSortBy: true,
    },
    {
      Header: "",
      accessor: "remove",
      id: "remove",
      Cell: ({ row: { original } }) => {
        if (original?.role === ROLE_DIRECTOR) {
          if (original?.company?.deleted_at) {
            return <ActivateCompany userId={original?.id} />
          } else {
            return <DeactivateCompany userId={original?.id} />
          }
        } else {
          return (
            <div className={"d-flex justify-content-end"}>
              <IconButton
                name={"remove"}
                bg
                onClick={() => props.onRemove(original)}
                disabled={props.removingLoading}
              />
            </div>
          )
        }
      },
      disableSortBy: true,
    },
  ])

  return (
    <Row>
      <Col lg="12">
        <Card>
          <CardBody className={"bg-neutral"}>
            <TableDataLoading
              isLoading={props.loading}
              isFailed={props.error}
              error={props.error}
            >
              <ReactTableView
                isLoading={props.loading}
                columns={columns}
                data={data}
                initialSort={props.sortValue}
                disableSortBy={false}
                onSort={props.onSortChange}
              />

              <Pagination
                data={props.meta}
                onPageChange={props.onPageChange}
                className={"mt-2"}
              />
            </TableDataLoading>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

UserAccountsTable.propTypes = {
  entities: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.any,
  page: PropTypes.any,
  meta: PropTypes.object,
  onPageChange: PropTypes.func,
  onSortChange: PropTypes.func,
  sortValue: PropTypes.array,
  onRemove: PropTypes.func,
  removingLoading: PropTypes.bool,
  onAiChatAccessUpdate: PropTypes.func,
}
